import styled from "styled-components";
import stepsBgImg from "../../assets/BG/asset_BG_Benefits.png";
const StepsDiv = styled.div`
  position: relative;
  height: 150vw;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url("${stepsBgImg}");
  @media (max-width: 480px) {
    height: 330vh;
  }
`;
const GetStartDiv = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: start;
  margin-left: 5vw;
  padding-top: 6vw;
  margin-bottom: 4vw;
  h1 {
    width: 20vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 4.92vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme }) => theme["text-color"]};
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.84vw;
    line-height: 2.92vw;
    color: ${({ theme }) => theme["text-color"]};
  }
  @media (max-width: 480px) {
    flex-direction: column;
    margin: 1rem 0 1rem 1rem;
    padding-top: 2rem;
    width: 80%;
    h1 {
      width: 60%;
      font-size: 32px;
      line-height: 110%;
    }
    p {
      font-size: 16px;
      line-height: 160%;
    }
  }
`;
const GetInTouchDiv = styled.div`
  margin-right: 10vw;
  width: 37vw;
  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
  }
`;
const StepsDivWraper = styled.div`
  margin: ${(props) =>
    props.step === 3
      ? "-10vw 6vw 0vw 8vw"
      : props.step === 1
      ? "6vw 6vw 0vw 8vw"
      : "-6vw 6vw 0vw 8vw"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  @media (max-width: 480px) {
    flex-direction: column;
    margin: 1rem;
  }
`;
const StepsImgDiv = styled.div`
  img {
    width: ${(props) =>
      props.step === 1 ? "50vw" : props.step === 2 ? "50vw" : "50vw"};
    margin-left: ${(props) =>
      props.step === 1 ? "-6vw" : props.step === 3 ? "-6vw" : "-6vw"};
  }
  @media (max-width: 480px) {
    img {
      width: 100%;
      margin-left: 0;
    }
  }
`;
const StepsTextDiv = styled.div`
  margin-left: ${(props) =>
    props.step === 3 ? "-1vw" : props.step === 1 ? "-1vw" : "0"};

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 1.3vw;
    text-transform: uppercase;
    color: ${({ theme }) => theme["step-text-color"]};
  }

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 2.4vw;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme["text-color"]};
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4vw;
    line-height: 2.1vw;
    color: ${({ theme }) => theme["text-color"]};
    margin-bottom: 1vw;
  }
  hr {
    border: 0.01vw solid rgba(189, 189, 189, 0.5);
  }
  @media (max-width: 480px) {
    margin: 0;
    h5 {
      font-size: 12px;
      line-height: 120%;
    }
    h2 {
      font-size: 18px;
      line-height: 20px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    hr {
      border: 1px solid rgba(189, 189, 189, 0.5);
    }
  }
`;
export {
  StepsDiv,
  GetStartDiv,
  GetInTouchDiv,
  StepsDivWraper,
  StepsImgDiv,
  StepsTextDiv,
};
