import styled from "styled-components";
import { ModalBody, Modal, Input } from "reactstrap";
const ModalBodyDiv = styled(ModalBody)`
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${({ theme }) => theme["form-text-color"]};
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme["aditional-text-color"]};
  }
  Label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme["light-color"]};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    color: ${({ theme }) => theme["form-sec-text-color"]};
    &::placeholder {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }

    &:-ms-input-placeholder {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }

    &::-ms-input-placeholder {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
  }
`;
const TextAreaDiv = styled(Input)`
  ::placeholder {
    color: ${({ theme }) => theme["form-sec-text-color"]};
  }
`;

const ModalDiv = styled(Modal)`
  border-radius: 6px;
  .modal-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }
  .modal-header .close {
    transform: translate(8px, -2px);
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
    border-radius: 6px;
    background: ${({ theme }) => theme["text-color"]};
    opacity: 1;
    transition: all 0.23s ease 0.1s;
    position: relative;
  }
  .close {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: ${({ theme }) => theme["light-color"]};
  }
  .modal-footer {
    border: none;
  }
`;

export { ModalBodyDiv, ModalDiv, TextAreaDiv };
