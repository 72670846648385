import styled from "styled-components";
import connectBgImg from "../../assets/BG/bg_asset_white_website.png";
import MobbileBgImg from "../../assets/BG/asset_BG_3rd fold.png";
const ConnectUsDiv = styled.div`
  position: relative;
  height: 40vw;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url("${connectBgImg}");
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    height: 80vh;
    margin-top: 0;
    justify-content: start;
    flex-direction: column;
    background-image: url("${MobbileBgImg}");
  }
`;
const ConnectUsText = styled.div`
  width: 70vw;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5.4vw;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 2.77vw;
    line-height: 3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme }) => theme["connect-us-text-color"]};
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.84vw;
    line-height: 3vw;
    color: ${({ theme }) => theme["secondry-text-color"]};
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 2rem 0rem;
    padding: 0rem 1rem;
    h1 {
      font-size: 24px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;
const ConnectUsImg = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 130vw;
  }
  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    img {
      width: 60%;
    }
  }
`;

export { ConnectUsDiv, ConnectUsText, ConnectUsImg };
