import React, { useState } from "react";
import { HeaderTextDiv, LogoDiv, HeaderDiv, LinkDiv } from "./Style";
import { ButtonDiv } from "../landingPage/Style";
import LogoImg from "../../assets/Logo/asset_logo_horizontal_white.png";
import BlackLogoImg from "../../assets/Logo/asset_logo_horizontal logo - black.png";
import menuIcon from "../../assets/Icon PNG 2X/ic_assets_menu.png";
import GetInTouchForm from "../getInTouchForm/GetInTouchForm";
import { useIsMobile } from "../../utils/Utils";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
const Header = (props) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <GetInTouchForm
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <HeaderDiv theme={props.theme}>
        {isMobile ? (
          <>
            <LogoDiv onClick={handleClick} id="Popover1" type="button">
              <img src={menuIcon} alt="logo" />
            </LogoDiv>
            <LinkDiv to="/">
              <LogoDiv>
                <img src={BlackLogoImg} alt="logo" />
              </LogoDiv>
            </LinkDiv>
            <UncontrolledPopover
              placement="bottom"
              isOpen={menuOpen}
              target="Popover1"
              trigger="legacy"
              toggle={() => setMenuOpen(!menuOpen)}
            >
              <PopoverBody>
                <HeaderTextDiv>
                  <LinkDiv to="#solution" theme={props.theme}>
                    <div>Solution</div>
                  </LinkDiv>
                  <LinkDiv to="#benefits" theme={props.theme}>
                    <div>Benefits</div>
                  </LinkDiv>
                  <LinkDiv to="/steps" theme={props.theme}>
                    <div>Steps</div>
                  </LinkDiv>
                </HeaderTextDiv>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ) : (
          <>
            <LinkDiv to="/">
              <LogoDiv>
                <img
                  src={props.theme === "black" ? LogoImg : BlackLogoImg}
                  alt="logo"
                />
              </LogoDiv>
            </LinkDiv>
            <HeaderTextDiv>
              <LinkDiv to="/#solution" theme={props.theme}>
                <div>Solution</div>
              </LinkDiv>
              <LinkDiv to="/#benefits" theme={props.theme}>
                <div>Benefits</div>
              </LinkDiv>
              <LinkDiv to="/steps" theme={props.theme}>
                <div>Steps</div>
              </LinkDiv>
              <ButtonDiv
                theme={props.theme}
                onClick={() => setFormModalOpen(!formModalOpen)}
              >
                Get In Touch
              </ButtonDiv>
            </HeaderTextDiv>
          </>
        )}
      </HeaderDiv>
    </>
  );
};
export default Header;
