import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { ButtonDiv } from "../landingPage/Style";
import {
  StepsDiv,
  GetStartDiv,
  GetInTouchDiv,
  StepsDivWraper,
  StepsImgDiv,
  StepsTextDiv,
} from "./Style";
import step1Img from "../../assets/Illustration PNG 2X/ill_asset_cards.png";
import step2Img from "../../assets/Illustration PNG 2X/illu_asset_website  - step 2.png";
import step3Img from "../../assets/Illustration PNG 2X/illu_asset_website - step 3.png";
import Footer from "../footer/Footer";
import GetInTouchForm from "../getInTouchForm/GetInTouchForm";
const Steps = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      <GetInTouchForm
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <Header theme={"white"} />
      <StepsDiv>
        <GetStartDiv>
          <h1>How do we get started?</h1>
          <GetInTouchDiv>
            <p>
              Create, Launch and Promote your own branded NFTs on top
              Metaverses.
            </p>
            <ButtonDiv
              theme="gradient"
              onClick={() => setFormModalOpen(!formModalOpen)}
            >
              Get In Touch
            </ButtonDiv>
          </GetInTouchDiv>
        </GetStartDiv>
        <StepsDivWraper>
          <StepsImgDiv step={1}>
            <img src={step1Img} alt="ill-img" />
          </StepsImgDiv>
          <StepsTextDiv step={1}>
            <h5>step 1</h5>
            <h2>Build your NFTs using Socialli Engine</h2>
            <hr />
            <p>
              Intuitive MetaMatter interface to mint and deploy your branded
              NFTs.
            </p>
            <p>
              CONNECT DIGITAL WALLET - Our blockchain ensures a transparent and
              error-free payment process of NFT marketplace websites.
            </p>
            <p>
              Our storefront helps manage NFT tokens swiftly thus making online
              inventory easy for you to control the supply and demand of your
              brand products.
            </p>
          </StepsTextDiv>
        </StepsDivWraper>
        <StepsDivWraper>
          <StepsImgDiv step={2}>
            <img src={step2Img} alt="ill-img" />
          </StepsImgDiv>
          <StepsTextDiv>
            <h5>step 2</h5>
            <h2>Launch your own NFTs in a metaverse of your choice</h2>
            <hr />
            <p>
              Once minted, we will create a customized NFT store on your domain.
            </p>

            <p>
              Our convenient auction system increases the control users have
              over their bids. Additionally, adding a watchlist allows your
              customers to monitor the current bid status of the auction.
            </p>
          </StepsTextDiv>
        </StepsDivWraper>
        <StepsDivWraper step={3}>
          <StepsImgDiv step={3}>
            <img src={step3Img} alt="ill-img" />
          </StepsImgDiv>
          <StepsTextDiv step={3}>
            <h5>step 3</h5>
            <h2>You’re all set!</h2>
            <hr />
            <p>
              Your NFTs will automatically be visible and purchasable on your
              chosen Metaverses.
            </p>

            <p>
              Track your NFTs performance across your selected metaverse and get
              quality analytics stats to stay on top of your game!
            </p>
          </StepsTextDiv>
        </StepsDivWraper>
      </StepsDiv>
      <Footer />
    </>
  );
};

export default Steps;
