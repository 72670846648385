import React, { useState, useEffect } from "react";
import {
  SlideShowDiv,
  SliderDiv,
  Slide,
  SlideShowDots,
  SlideShowDot,
} from "./Style";

const delay = 2500;

const SlideShow = (props) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = React.useRef(null);
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex ===
        props.children.filter((comp) => comp.props.children).length - 1
          ? 0
          : prevIndex + 1
      );
    }, delay);
    return () => {
      resetTimeout();
    };
  }, [index,props.children]);

  return (
    <SlideShowDiv>
      <SliderDiv style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
        {props.children
          .filter((comp) => comp.props.children)
          .map((comp, index) => (
            <Slide key={index}>{comp}</Slide>
          ))}
      </SliderDiv>
      <SlideShowDots>
        {props.children
          .filter((comp) => comp.props.children)
          .map((_, idx) => {
            return (
              <SlideShowDot
                key={idx}
                poss={idx}
                index={index}
                onClick={() => {
                  setIndex(idx);
                }}
              ></SlideShowDot>
            );
          })}
      </SlideShowDots>
    </SlideShowDiv>
  );
};
export default SlideShow;
