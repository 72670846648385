import styled from "styled-components";

const SlideShowDiv = styled.div`
  margin: 0 auto;
  width: 28rem;
  overflow: hidden;
  max-width: 340px;
`;
const SliderDiv = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
  padding: 1rem 0;
`;

const Slide = styled.div`
  display: inline-block;

  height: 240px !important;
  min-height: 10rem;
  width: 100%;
  border-radius: 40px;
  p {
    white-space: normal;
    width: 18rem;
  }
`;
const SlideShowDots = styled.div`
  text-align: center;
`;
const SlideShowDot = styled.div`
  display: inline-block;

  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: ${(props) =>
    props.poss === props.index ? "#5d7c30" : "#c4c4c4"};
`;
export { SlideShowDiv, SliderDiv, Slide, SlideShowDot, SlideShowDots };
