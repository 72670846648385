const textColor = "#FFFFFF";
const btnBgColor = "#FFFFFF";
const blackBtnColor = "#000000";
const secondryTextColor = "#757575";
const solutionTextColor = "#596375";
const aditionalTextColor = "#6E798C";
const linkTextColor = "#007AE9";
const blueTextColor = "#64FFDA";
const connectUsTextColor = "#424242";
const stepsTextColor = "#2AF6FF";
const formTextColor = "#596375";
const lightColor = "#5E5873";
const formSecTextColor = "#B9B9C3";
const headerTextColor = "#7d7d7d";
const lineColor = "#ebeced";
const theme = {
  "text-color": textColor,
  "btn-bg-color": btnBgColor,
  "black-color": blackBtnColor,
  "secondry-text-color": secondryTextColor,
  "solution-text-color": solutionTextColor,
  "aditional-text-color": aditionalTextColor,
  "link-text-color": linkTextColor,
  "blue-text-color": blueTextColor,
  "connect-us-text-color": connectUsTextColor,
  "step-text-color": stepsTextColor,
  "form-text-color": formTextColor,
  "light-color": lightColor,
  "form-sec-text-color": formSecTextColor,
  "header-text-color": headerTextColor,
  "line-color": lineColor,
};
export { theme };
