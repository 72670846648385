import React from "react";
import {
  SolutionDiv,
  SolutionHeadingDiv,
  SolutionCard,
  SolutionCardsDiv,
} from "./Style";
import solImg1 from "../../assets/Illustration PNG 2X/illu_asset_website - image 1.png";
import solImg2 from "../../assets/Illustration PNG 2X/illu_asset_website - image 2.png";
import solImg3 from "../../assets/Illustration PNG 2X/illu_asset_website - image 3.png";
import { useIsMobile } from "../../utils/Utils";
import SlideShow from "../../components/slideShow/SlideShow";
const Solutions = () => {
  const isMobile = useIsMobile();
  return (
    <SolutionDiv id="solution">
      <SolutionHeadingDiv>
        <h1>Who is this for ?</h1>
        <p>Decentralized NFT Solutions for Businesses</p>
      </SolutionHeadingDiv>
      {isMobile ? (
        <SlideShow>
          <SolutionCard>
            <img src={solImg1} alt="solution-img" />
            <h5>
              Louis Vuitton released Louis The Game, a puzzle adventure game in
              which players can collect NFTs.
            </h5>
            <hr />
            <p>
              Its 200th anniversary marked the company’s first very serious
              efforts into the metaverse. Fashion house Louis Vuitton did ...
              <span>Read more</span>
            </p>
          </SolutionCard>
          <SolutionCard>
            <img src={solImg2} alt="solution-img" />
            <h5>
              Nike now has access to a premier digital fashion brand and the
              metaverse ready CloneX avatars.
            </h5>
            <hr />
            <p>
              December 2021, Nike acquired RTFKT (Artifact) Studios, a digital
              fashion brand with a strong presence in the NFT...
              <span>Read more</span>
            </p>
          </SolutionCard>
          <SolutionCard>
            <img src={solImg3} alt="solution-img" />
            <h5>
              Coca Cola launched their first NFT collection, together with a
              virtual event in Decentraland.
            </h5>
            <hr />
            <p>
              Coca-Cola is selling a series of four NFTs, including a pixelated
              version of Cokeʼs classic 1956 vending machine, a ...
              <span>Read more</span>
            </p>
          </SolutionCard>
        </SlideShow>
      ) : (
        <>
          <SolutionCardsDiv>
            <SolutionCard>
              <img src={solImg1} alt="solution-img" />
              <h5>
                Louis Vuitton released Louis The Game, a puzzle adventure game
                in which players can collect NFTs.
              </h5>
              <hr />
              <p>
                Its 200th anniversary marked the company’s first very serious
                efforts into the metaverse. Fashion house Louis Vuitton did ...
                <span>Read more</span>
              </p>
            </SolutionCard>
            <SolutionCard>
              <img src={solImg2} alt="solution-img" />
              <h5>
                Nike now has access to a premier digital fashion brand and the
                metaverse ready CloneX avatars.
              </h5>
              <hr />
              <p>
                December 2021, Nike acquired RTFKT (Artifact) Studios, a digital
                fashion brand with a strong presence in the NFT...
                <span>Read more</span>
              </p>
            </SolutionCard>
            <SolutionCard>
              <img src={solImg3} alt="solution-img" />
              <h5>
                Coca Cola launched their first NFT collection, together with a
                virtual event in Decentraland.
              </h5>
              <hr />
              <p>
                Coca-Cola is selling a series of four NFTs, including a
                pixelated version of Cokeʼs classic 1956 vending machine, a ...
                <span>Read more</span>
              </p>
            </SolutionCard>
          </SolutionCardsDiv>
        </>
      )}
    </SolutionDiv>
  );
};

export default Solutions;
