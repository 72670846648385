import styled from "styled-components";
import landingBgImg from "../../assets/BG/asset_BG_website - banner.png";
import { BLACK_COLOR, WHITE_COLOR } from "../../utils/Constant";

const LandingPageDiv = styled.div`
  position: relative;
  height: 76vw;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url("${landingBgImg}");
  @media (max-width: 480px) {
    height: 170vh;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  gap: 1vw;
  width: 12vw;
  height: 4vw;
  font-size:1.3vw;
  line-height: 1.8vw;
  color: ${(props) =>
    props.theme === "black" || props.theme === "gradient"
      ? BLACK_COLOR
      : WHITE_COLOR};
  cursor: pointer;
  background: ${(props) =>
    props.theme === "gradient"
      ? `linear-gradient(
    128.43deg,
    #e2d0fc -14.89%,
    #fbcedf 9.62%,
    #e6e7c0 37.47%,
    #c1e9ea 75.05%,
    #ded1fc 100.31%
  );`
      : props.theme === "black"
      ? WHITE_COLOR
      : BLACK_COLOR};
  box-shadow: inset -12px -8px 16px rgba(0, 0, 0, 0.15),
    inset 12px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10vw;
  @media(max-width:480px){
 width: 156px;
  height: 51px;
  font-size:16px;
  line-height: 24px;
  }
`;
const HomePageDiv = styled.div`
  display: flex;
  justify-content: start;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const LandingPageText = styled.div`
  width: 34vw;
  margin-left:7vw;
  margin-top: 4vw;
  text-align: start;
  color:${({theme})=> theme["text-color"]};
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.8vw;
    line-height: 3vw;
    margin: 2vw 0;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin: 1rem 1rem;
    h1 {
      width:66%;
      font-weight: 800;
      font-size: 32px;
      line-height: 110%;
      margin-bottom: 2rem;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      padding-right: 1rem;
      margin-bottom: 1rem;
    }
  }
`;
const BrandLogsDiv = styled.div`
  display: flex;
  justify-content:center;
  gap: 1vw;
  width: 100%;
  margin: 2vw;
  margin-left:0vw;
  img {
    width: 16vw;
    height:2.4vw;
    margin-right: .2vw;
  }
  @media (max-width: 480px) {
    gap:1rem;
    margin:2rem 0rem;
    img {
      height:25px;
      width: 100%;
    }
  }
`;
const MetamatterTextDiv = styled.div`
  width: 50vw;
  margin: 2vw auto;
  img {
    width: 40vw;
    height: 32vw;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
    img {
      width: 80%;
      height: 16rem;
    }
  }
`;
const PartnersDiv = styled.div`
  display: flex;
  justify-content:start;
  @media (max-width: 480px) {
    flex-direction: column;
    padding-left: 1rem;
    width: 99%;
  }
`;
const PartnersTextDiv = styled.div`
  width: 20vw;
  margin-left: 7vw;
  margin-top:4vw;
  text-align: start;
  margin-right: 12vw;
  h1 {
    width: 10vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color:${({theme})=> theme["text-color"]};
  }
  @media (max-width: 480px) {
    margin-left: 0;
    h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 110%;
      letter-spacing: 0.01em;
    }
  }
`;
const PartnersBrandsDiv = styled.div`
  width: 50vw;
  height: 20vw;
  display: flex;
  margin-top: 6vw;

  flex-direction: column;
  justify-content: start;
  gap: 0.5vw;
  div{
    display: flex;
    margin-right: 1vw;

  }
  @media (max-width: 480px) {
    width: 100%;
    height:auto;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    padding: 0;
    margin: 0 auto;
    gap: 8px;
    div{
      
    }
  }
`;
const MetaBrandsDiv = styled.div`
  img {
    width:9vw;
  }
  width: 15.4vw;
  height:6.1vw;  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(props) =>
    props.bg === "white"
      ? `
  linear-gradient(
    128.43deg,
    #e2d0fc -14.89%,
    #fbcedf 9.62%,
    #e6e7c0 37.47%,
    #c1e9ea 75.05%,
    #ded1fc 100.31%
  );`
      : "#212121"};
  box-shadow: inset -12px -8px 16px rgba(0, 0, 0, 0.15),
    inset 12px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 1vw;
  padding: 1vw;
  @media (max-width: 480px) {
    width: 100px;
    height:50px;
    padding: 1rem;
    border-radius: 4px;
    img {
      width: 70px;
    }
  }
`;

export {
  LandingPageDiv,
  ButtonDiv,
  LandingPageText,
  BrandLogsDiv,
  MetamatterTextDiv,
  HomePageDiv,
  MetaBrandsDiv,
  PartnersDiv,
  PartnersBrandsDiv,
  PartnersTextDiv,
};
