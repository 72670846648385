import styled from "styled-components";

const SolutionDiv = styled.div`
  padding-top: 4vw;
  @media (max-width: 480px) {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`;
const SolutionHeadingDiv = styled.div`
  text-align: center;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    background: linear-gradient(
      128.43deg,
      #e2d0fc -14.89%,
      #fbcedf 9.62%,
      #e6e7c0 37.47%,
      #c1e9ea 75.05%,
      #ded1fc 100.31%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.8vw;
    line-height: 1.8vw;
    color:${({theme})=>theme["secondry-text-color"]};
  }
  @media (max-width: 480px) {
    text-align: start;
    padding: 1vw;
    margin-top: 0rem;
    h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 110%;
    }
    p{
      font-size: 18px;
      line-height:24px;
    }
  }
`;
const SolutionCardsDiv = styled.div`
  display: flex;
  justify-content: center;
  gap:1.1vw;
  margin-bottom: 2vw;
  @media (max-width: 500px) {
    display: none;
    gap:0;
  }
`;
const SolutionCard = styled.div`
  width: 31vw;
  background: ${({theme})=>theme["text-color"]};
  box-shadow: 0px 24px 64px rgba(48, 63, 159, 0.2);
  border-radius: 1vw;
  text-align: start;
  img {
    width: 100%;
  }
  h5 {
    padding: 1vw 2.7vw;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.3vw;
    line-height: 1.8vw;
    color:${({theme})=>theme["solution-text-color"]};
  }
  p {
    padding: 1vw 2.7vw;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.23vw;
    line-height: 2.1vw;
    color:${({theme})=>theme["aditional-text-color"]};
  }
  hr {
    background:${({theme})=>theme["line-color"]};
    margin: 0rem 2.7vw;
  }
  span {
    color:${({theme})=>theme["link-text-color"]};
  }
  @media (max-width: 500px) {
    width: 320px;
    h5 {
      display: flex;
      white-space: pre-wrap;
      text-align: start;
      font-size: 18px;
      line-height: 24px;
      padding: 1rem 0.5rem;
    }
    p {
      padding: 1rem 0.5rem;
      font-size: 16px;
      line-height: 28px;
    }
    img {
      box-sizing: border-box;
    }
  }
`;

export { SolutionDiv, SolutionCardsDiv, SolutionHeadingDiv, SolutionCard };
