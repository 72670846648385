import "./App.css";
import { ThemeProvider } from "styled-components";
import MainPage from "./container/mainPage/MainPage";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Steps from "./container/steps/Steps";
import { theme } from "./utils/theme";
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={{ ...theme }}>
        <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/steps" element={<Steps />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
