import React from "react";
import LandingPage from "../landingPage/LandingPage";
import Solutions from "../solutions/Solution";
import Benefits from "../benefits/Benefits";
import ConnectUs from "../connectUs/ConnectUs";
import Footer from "../footer/Footer";
const MainPage = () => {
  return (
    <>
      <LandingPage />
      <Solutions />
      <Benefits />
      <ConnectUs />
      <Footer />
    </>
  );
};
export default MainPage;
