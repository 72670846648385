import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { addDoc } from "firebase/firestore";
import {
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  CardTitle,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { ButtonDiv } from "../landingPage/Style";
import { ModalBodyDiv, ModalDiv, TextAreaDiv } from "./Style";
import { WHITE_COLOR } from "../../utils/Constant";
import { collRef } from "../../config/axios/Firebase";
const GetInTouchForm = (props) => {
  const { formModalOpen, setFormModalOpen } = props;
  const [formData, setFormData] = useState(null);
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const mobileFormat='^([0|+[0-9]{1,5})?([7-9][0-9]{9})$';

  const closeHandler = () => {
    setFormModalOpen(!formModalOpen);
  };
  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const saveHandler = () => {
    if (!formData.name || !formData.email) {
      alert("please enter name and email correctly");
    } else if (!formData.email.match(mailFormat)) {
      alert("You have entered an invalid email address!");
    }
    else if(!formData.number.match(mobileFormat)){
      alert("You have entered an invalid number!");
    }
     else {
      addDoc(collRef, { ...formData });
      setFormData(null);
      closeHandler();
    }
  };
  return (
    <ModalDiv
      isOpen={formModalOpen}
      toggle={() => closeHandler()}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        style={{ backgroundColor: WHITE_COLOR }}
        toggle={() => closeHandler()}
      ></ModalHeader>
      <ModalBodyDiv>
        <Row className="d-flex flex-column  text-center mx-2">
          <h1>Get In Touch</h1>
          <p>Our team will get in touch with you!</p>
        </Row>
        <CardTitle className="text-start">
          <p>Contact Details -</p>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Full Name</Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Enter your full name"
                    type="text"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="mobile-number">Mobile Number</Label>
                  <Input
                    id="number"
                    name="number"
                    placeholder="Mobile Number"
                    type="number" 
                    min="0"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Company Name</Label>
                  <Input
                    id="name"
                    name="company_name"
                    placeholder="Enter your company name"
                    type="text"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Company Email ID</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Enter your email ID"
                    type="email"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Your Designation </Label>
                  <Input
                    id="designation"
                    name="designation"
                    placeholder="Enter your designation"
                    type="text"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <br />
          <p>Appointment Details -</p>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleDate">Date</Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="Select a date"
                    type="text"
                    min={new Date().toISOString().split("T")[0]}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleTime">Time</Label>
                  <Input
                    id="exampleTime"
                    name="time"
                    placeholder="Select a time"
                    type="text"
                    min={new Date().toISOString().split("T")[0]}
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Query Details (Optional)</Label>
                  <TextAreaDiv
                    id="textarea"
                    name="query"
                    placeholder="Enter your query details"
                    type="textarea"
                    onChange={onChangeHandler}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardTitle>
      </ModalBodyDiv>
      <ModalFooter className="d-flex  justify-content-end align-items-center">
        <div className="mr-4" role={"button"} onClick={closeHandler}>
          Cancel
        </div>
        <ButtonDiv onClick={(e) => saveHandler(e)}>Save</ButtonDiv>
      </ModalFooter>
    </ModalDiv>
  );
};

export default GetInTouchForm;
