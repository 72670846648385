import React from "react";
import {
  BenefitsBgDiv,
  BenefitsHomeDiv,
  BenefitsImgDiv,
  BenefitsTextBgDiv,
  BenefitsTextDiv,
  GetStartCard,
  GetStartCardsDiv,
  GetStartDiv,
  GetStartLowerCard,
  GetStartTextDiv,
  GetStartUpperCard,
} from "./Style";
import benefitIll from "../../assets/Illustration PNG 2X/ill_asset_web_benefits_logo.png";

const Benefits = () => {
  return (
    <BenefitsBgDiv id="benefits">
      <BenefitsHomeDiv>
        <BenefitsImgDiv>
          <h1>What are the benefits?</h1>
          <img src={benefitIll} alt="benefits-ill" />
        </BenefitsImgDiv>
        <BenefitsTextBgDiv>
          <BenefitsTextDiv>
            <h3>1</h3>
            <p>Personalised Metaverse strategy for your brand</p>
          </BenefitsTextDiv>
          <BenefitsTextDiv>
            <h3>2</h3>
            <p>One stop shop for managing your NFT</p>
          </BenefitsTextDiv>
          <BenefitsTextDiv>
            <h3>3</h3>
            <p>Automatic NFT listing on top Metaverses</p>
          </BenefitsTextDiv>
          <BenefitsTextDiv>
            <h3>4</h3>
            <p>Unified Sales Dashboard</p>
          </BenefitsTextDiv>
          <BenefitsTextDiv>
            <h3>5</h3>
            <p>Out of the box Limited Edition NFT minting support</p>
          </BenefitsTextDiv>
          <BenefitsTextDiv>
            <h3>6</h3>
            <p>
              Lifelong royalties as your NFTs are resold in secondary markets
            </p>
          </BenefitsTextDiv>
        </BenefitsTextBgDiv>
      </BenefitsHomeDiv>
      <GetStartDiv>
        <GetStartTextDiv>
          <h1>How do we get started?</h1>
        </GetStartTextDiv>
        <GetStartCardsDiv>
          <GetStartCard>
            <GetStartUpperCard>
              <h6>Step 1</h6>
              <h1>Build your NFTs using Socialli Engine</h1>
            </GetStartUpperCard>
            <GetStartLowerCard>
              <p>
                Intuitive MetaMatter interface to mint and deploy your branded
                NFTs.
              </p>
              <h5>Know more</h5>
            </GetStartLowerCard>
          </GetStartCard>
          <GetStartCard>
            <GetStartUpperCard>
              <h6>Step 2</h6>
              <h1>Launch your own NFTs in a Metaverse of your choice</h1>
            </GetStartUpperCard>
            <GetStartLowerCard>
              <p>
                Once minted, we will create a customized NFT store on your
                domain.
              </p>
              <h5>Know more</h5>
            </GetStartLowerCard>
          </GetStartCard>
          <GetStartCard>
            <GetStartUpperCard>
              <h6>Step 3</h6>
              <h1>You’re all set!</h1>
            </GetStartUpperCard>
            <GetStartLowerCard>
              <p>
                Your NFTs will automatically be visible and purchasable on your
                chosen Metaverses.
              </p>
              <h5>Know more</h5>
            </GetStartLowerCard>
          </GetStartCard>
        </GetStartCardsDiv>
      </GetStartDiv>
    </BenefitsBgDiv>
  );
};

export default Benefits;
