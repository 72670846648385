import React from "react";
import {
  FooterDiv,
  FooterTextDiv,
  SocialIconsDiv,
  LinkDiv,
} from "./Style";
import socialliIcon from "../../assets/Icon PNG 2X/asset_logo_socialli-dark-bg.png";
import bscScanIcon from "../../assets/Icon PNG 2X/asset_logo_BSCsacn.png";
import facebookIcon from "../../assets/Icon PNG 2X/ic_assets_facebook.png";
import InstagramIcon from "../../assets/Icon PNG 2X/ic_assets_instagram.png";
import twitterIcon from "../../assets/Icon PNG 2X/ic_assets_twitter.png";
import whatsappIcon from "../../assets/Icon PNG 2X/ic_assets_whatsapp.png";
import linkedinIcon from "../../assets/Icon PNG 2X/ic_assets_linkden.png";
import { useIsMobile } from "../../utils/Utils";

const Footer = () => {
  const isMobile = useIsMobile();
  return (
    <FooterDiv>
      {isMobile ? (
        <>
          <div>
            <FooterTextDiv>
              <h3>Powered By</h3>
              <img src={socialliIcon} alt="socialli-icon" />
              <h3>Token Tracker</h3>
              <img src={bscScanIcon} alt="bsc-scan-icon" />
            </FooterTextDiv>
            <FooterTextDiv>
              <h3>Navigate</h3>
              <LinkDiv to="/#benefits">
                <p>Benefits</p>
              </LinkDiv>
              <LinkDiv to="/steps">
                <p>Steps</p>
              </LinkDiv>
              <p>Request demo</p>
            </FooterTextDiv>
          </div>
          <div>
            <FooterTextDiv>
              <h3>Social Media</h3>
              <SocialIconsDiv>
                <img src={facebookIcon} alt="facebook-icon" />
                <img src={InstagramIcon} alt="instagram-icon" />
                <img src={twitterIcon} alt="twitter-icon" />
                <img src={whatsappIcon} alt="whatsapp-icon" />
                <img src={linkedinIcon} alt="linkedin-icon" />
              </SocialIconsDiv>
            </FooterTextDiv>
            <FooterTextDiv>
              <h3>Contact</h3>
              <p>+012 3456789</p>
              <p>Mettamatter@gmail.com</p>
              <h6>©{new Date().getFullYear()} All rights reserved</h6>
            </FooterTextDiv>
          </div>
        </>
      ) : (
        <>
          <FooterTextDiv>
            <h3>Powered By</h3>
            <img src={socialliIcon} alt="socialli-icon" />
            <br />
            <h3>Token Tracker</h3>
            <img src={bscScanIcon} alt="bsc-scan-icon" />
          </FooterTextDiv>
          <FooterTextDiv>
            <h3>Navigate</h3>

            <LinkDiv to="/#benefits">
              <p>Benefits</p>
            </LinkDiv>
            <LinkDiv to="/steps">
              <p>Steps</p>
            </LinkDiv>
            <p>Request demo</p>
          </FooterTextDiv>

          <FooterTextDiv>
            <h3>Social Media</h3>
            <SocialIconsDiv>
              <img src={facebookIcon} alt="facebook-icon" />
              <img src={InstagramIcon} alt="instagram-icon" />
              <img src={twitterIcon} alt="twitter-icon" />
              <img src={whatsappIcon} alt="whatsapp-icon" />
              <img src={linkedinIcon} alt="linkedin-icon" />
            </SocialIconsDiv>
          </FooterTextDiv>
          <FooterTextDiv>
            <h3>Contact</h3>
            <p>+012 3456789</p>
            <p>Mettamatter@gmail.com</p>
            <h6>©{new Date().getFullYear()} All rights reserved</h6>
          </FooterTextDiv>
        </>
      )}
    </FooterDiv>
  );
};

export default Footer;
