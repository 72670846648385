import React, { useState } from "react";
import { ConnectUsDiv, ConnectUsImg, ConnectUsText } from "./Style";
import { ButtonDiv } from "../landingPage/Style";
import connectMetaImg from "../../assets/Illustration PNG 2X/illu_asset_logo.png";
import GetInTouchForm from "../getInTouchForm/GetInTouchForm";
import { useIsMobile } from "../../utils/Utils";
const ConnectUs = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const isMobile = useIsMobile();
  return (
    <ConnectUsDiv>
      <GetInTouchForm
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <ConnectUsText>
        <h1>Connect with us.</h1>
        <p>Let’s launch your Brand to the Multiverse.</p>
        <ButtonDiv
          theme="gradient"
          onClick={() => setFormModalOpen(!formModalOpen)}
        >
          Get In Touch
        </ButtonDiv>
      </ConnectUsText>
      {isMobile && (
        <ConnectUsImg>
          <img src={connectMetaImg} alt="meta-matter-logo" />
        </ConnectUsImg>
      )}
    </ConnectUsDiv>
  );
};

export default ConnectUs;
