/* eslint-disable no-restricted-globals */
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "firebase/app-check";
import {
  FIREBASE_APIKEY,
  FIREBASE_APP_CHECK_DEBUG_TOKEN,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_RECAPTCHA,
} from "./Constant";

self.FIREBASE_APP_CHECK_DEBUG_TOKEN = FIREBASE_APP_CHECK_DEBUG_TOKEN;

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};
console.log(firebaseConfig, "config...");
// Initialize Firebase and Firestore
const app = initializeApp({
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
});
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(FIREBASE_RECAPTCHA),
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore();
export const collRef = collection(db, "users");
