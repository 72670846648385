import styled from "styled-components";
import benefitBgImg from "../../assets/BG/asset_BG_Benifits.png";
import mobileBgImg from "../../assets/BG/asset_BG_Benefits.png";
const BenefitsBgDiv = styled.div`
  position: relative;
  height: 80vw;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url("${benefitBgImg}");
  @media (max-width: 480px) {
    height: 270vh;
    background-image: url("${mobileBgImg}");
  }
`;
const BenefitsHomeDiv = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const BenefitsImgDiv = styled.div`
  margin: 9.2vw 0 0 5.4vw;
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 26vw;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme }) => theme["text-color"]};
    padding-right: 6vw;
  }
  img {
    margin-top: -1vw;
    width: 20vw;
  }
  @media (max-width: 480px) {
    width: 80%;
    margin: 1rem;
    h1 {
      width: 60%;
      font-weight: 800;
      font-size: 32px;
      line-height: 110%;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }
    img {
      margin-top: 0;
      width: 80%;
    }
  }
`;
const BenefitsTextBgDiv = styled.div`
  width: 70vw;
  margin-top: 5.6vw;
  padding: 0 3.5vw;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 3rem;
    padding: 0 0rem;
  }
`;
const BenefitsTextDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 30vw;
  h3 {
    background: linear-gradient(
      128.43deg,
      #e2d0fc -14.89%,
      #fbcedf 9.62%,
      #e6e7c0 37.47%,
      #c1e9ea 75.05%,
      #ded1fc 100.31%
    );
    box-shadow: inset -12px -8px 16px rgba(0, 0, 0, 0.15),
      inset 12px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 3vw;
    height: 3vw;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 1.6vw;
    line-height: 1.8vw;
    transform: rotate(0deg);
    margin-right: 3.5vw;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 1.8vw;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme["text-color"]};
    transform: rotate(0deg);
    width: 16vw;
    height: 8vw;
  }
  @media (max-width: 480px) {
    align-items: flex-start;
    justify-content: start;
    text-align: start;
    align-items: flex-start;
    padding: 1rem;
    width: 50%;
    h3 {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      font-size: 14px;
      line-height: 16px;
    }
    p {
      width: 70%;
      font-size: 16px;
      line-height: 24px;
      height: 80px;
    }
    margin-bottom: 16px;
  }
`;

const GetStartDiv = styled.div`
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    margin-top: 1rem;
    flex-direction: column;
  }
`;
const GetStartTextDiv = styled.div`
  margin-left: 5.4vw;
  width: 20vw;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    line-height: 5.3vw;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme }) => theme["text-color"]};
  }
  @media (max-width: 480px) {
    margin-left: 1rem;
    width: 36%;
    text-align: start;
    h1 {
      font-size: 32px;
      line-height: 110%;
    }
  }
`;
const GetStartCardsDiv = styled.div`
  padding: 0 1vw;
  width: 70vw;
  display: flex;
  justify-content: start;
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }
`;
const GetStartCard = styled.div`
  width: 28.7vw;
  height: 28.6vw;
  margin: 0 0.6vw;
  @media (max-width: 480px) {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
`;
const GetStartUpperCard = styled.div`
  height: 13.6vw;
  margin: 0;
  padding: 0 1.6vw;
  background: linear-gradient(
    152.43deg,
    #e2d0fc 4%,
    #fbcedf 18.66%,
    #e6e7c0 35.32%,
    #c1e9ea 57.8%,
    #ded1fc 72.92%
  );
  box-shadow: inset -12px -8px 16px rgba(0, 0, 0, 0.15),
    inset 12px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 1vw 1vw 0px 0px;
  text-align: start;
  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 1.3vw;
    text-transform: uppercase;
     color: ${({ theme }) => theme["black-color"]};;
    padding-top: 1.8vw;
  }
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 2.4vw;
    display: flex;
    align-items: center;
     color: ${({ theme }) => theme["black-color"]};;
    margin-bottom: 0;
    /* padding: 1vw 2.6vw 2vw 2.6vw; */
  }
  @media (max-width: 480px) {
    margin: 0;
    padding: 1rem;
    height: 100%;
    border-radius: 8px 8px 0 0;
    h6 {
      font-size: 12px;
      line-height: 14px;
    }
    h1 {
      font-size: 18px;
      line-height: 22px;
      /* padding: 1rem; */
    }
  }
`;
const GetStartLowerCard = styled.div`
  margin: 0;
  height: 15vw;
  padding: 0 2.2vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);

  text-align: start;
  border-radius: 0px 0px 1vw 1vw;
  border: 1px solid ${({ theme }) => theme["text-color"]};
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.3vw;
    line-height: 2.1vw;
    color: ${({ theme }) => theme["text-color"]};
    margin-top: 0;
    padding-top: 1.8vw;
  }
  h5 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 1vw;
    line-height: 1.3vw;
     color: ${({ theme }) => theme["blue-text-color"]};;
    margin-bottom: 1.8vw;
  }
  @media (max-width: 480px) {
    border-radius: 8px;
    border-radius: 0 0 8px 8px;
    padding: 1.4rem 1rem;
    height: auto;
    text-align: start;
    p {
      font-size: 16px;
      line-height: 20px;
      padding-top: 0;
    }
    h5 {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export {
  BenefitsBgDiv,
  BenefitsHomeDiv,
  BenefitsImgDiv,
  BenefitsTextBgDiv,
  BenefitsTextDiv,
  GetStartDiv,
  GetStartCard,
  GetStartCardsDiv,
  GetStartLowerCard,
  GetStartTextDiv,
  GetStartUpperCard,
};
