import React, { useState, useEffect } from "react";
import {
  LandingPageDiv,
  ButtonDiv,
  LandingPageText,
  BrandLogsDiv,
  MetamatterTextDiv,
  HomePageDiv,
  MetaBrandsDiv,
  PartnersDiv,
  PartnersTextDiv,
  PartnersBrandsDiv,
} from "./Style";
import Header from "../header/Header";
import polkaDotIcon from "../../assets/Icon PNG 2X/asset_logo_polkadot india.png";
import ethereumIcon from "../../assets/Icon PNG 2X/asset_logo_ethereum.png";
import polygonIcon from "../../assets/Icon PNG 2X/asset_logo_polygon.png";
import illustrationImg from "../../assets/Illustration PNG 2X/illu_asset_website - banner.png";
import sandBoxIcon from "../../assets/Icon PNG 2X/asset_logo_sandbox.png";
import mirandusIcon from "../../assets/Icon PNG 2X/asset_logo_mirandus.png";
import robloxIcon from "../../assets/Icon PNG 2X/asset_logo_roblox.png";
import dmatIcon from "../../assets/Icon PNG 2X/asset_logo_dmat.png";
import altxIcon from "../../assets/Icon PNG 2X/asset_logo_altx.png";
import axieIcon from "../../assets/Icon PNG 2X/asset_logo_axie.png";
import sxswIcon from "../../assets/Icon PNG 2X/asset_logo_sxsw.png";
import riotgameIcon from "../../assets/Icon PNG 2X/asset_logo_riotgame.png";
import decentrallandIcon from "../../assets/Icon PNG 2X/asset_logo_decentralland.png";
import nvidiaIcon from "../../assets/Icon PNG 2X/asset_logo_nvidia.png";
import staratlasIcon from "../../assets/Icon PNG 2X/asset_logo_staratlas.png";
import treeverseIcon from "../../assets/Icon PNG 2X/asset_logo_treeverse.png";
import GetInTouchForm from "../getInTouchForm/GetInTouchForm";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../utils/Utils";
const LandingPage = () => {
  const isMobile = useIsMobile();
  const [formModalOpen, setFormModalOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const scroll = () => {
      const section = document.querySelector(location.hash);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    setTimeout(() => {
      scroll();
    }, 0);
  }, [location]);
  return (
    <>
      <GetInTouchForm
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <LandingPageDiv>
        <Header theme={"black"} />
        <HomePageDiv>
          <LandingPageText>
            <h1>Is your Brand Metaverse ready ? </h1>
            <p>
              Create, Launch and Promote your own branded NFTs on top
              Metaverses.
            </p>
            <ButtonDiv
              onClick={() => setFormModalOpen(!formModalOpen)}
              theme="gradient"
            >
              Get In Touch
            </ButtonDiv>
            <BrandLogsDiv>
              <img src={polkaDotIcon} alt="polkadot-icon" />
              <img src={ethereumIcon} alt="ethereum-icon" />
              <img src={polygonIcon} alt="polygon-icon" />
            </BrandLogsDiv>
          </LandingPageText>
          <MetamatterTextDiv>
            <img src={illustrationImg} alt="meta-matter-ill" />
          </MetamatterTextDiv>
        </HomePageDiv>
        <PartnersDiv>
          <PartnersTextDiv>
            <h1>Our Partner network</h1>
          </PartnersTextDiv>
          <PartnersBrandsDiv>
            {isMobile ? (
              <>
                <MetaBrandsDiv>
                  <img src={sandBoxIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={mirandusIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv bg={"white"}>
                  <img src={robloxIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={dmatIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>

                <MetaBrandsDiv>
                  <img src={altxIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={axieIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={sxswIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={riotgameIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>

                <MetaBrandsDiv bg={"white"}>
                  <img src={decentrallandIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={nvidiaIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv>
                  <img src={staratlasIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
                <MetaBrandsDiv bg={"white"}>
                  <img src={treeverseIcon} alt="meta-matter-icon" />
                </MetaBrandsDiv>
              </>
            ) : (
              <>
                <div>
                  <MetaBrandsDiv>
                    <img src={sandBoxIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={mirandusIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv bg={"white"}>
                    <img src={robloxIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={dmatIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                </div>
                <div>
                  <MetaBrandsDiv>
                    <img src={altxIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={axieIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={sxswIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={riotgameIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                </div>
                <div>
                  <MetaBrandsDiv bg={"white"}>
                    <img src={decentrallandIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={nvidiaIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv>
                    <img src={staratlasIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                  <MetaBrandsDiv bg={"white"}>
                    <img src={treeverseIcon} alt="meta-matter-icon" />
                  </MetaBrandsDiv>
                </div>
              </>
            )}
          </PartnersBrandsDiv>
        </PartnersDiv>
      </LandingPageDiv>
    </>
  );
};

export default LandingPage;
