import styled from "styled-components";
import { Link } from "react-router-dom";
import { HEADER_TEXT_COLOR, WHITE_COLOR } from "../../utils/Constant";

const HeaderDiv = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: ${(props) =>
    props.theme === "black" ? WHITE_COLOR : HEADER_TEXT_COLOR};
  padding: ${(props) => (props.theme === "black" ? "1rem" : "1rem")};
  padding-right: 4.2vw;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.7vw;
  letter-spacing: 0.02em;
  height:5.5vw;

  @media (max-width: 480px) {
    height: auto;
    background:${({theme})=>theme["text-color"]};
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.15);
  }
`;

const HeaderTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
    padding: 0rem 2rem 0 1rem;
    flex-wrap: nowrap;
    gap: 8px;
    color:${({theme})=>theme["header-text-color"]};
    font-size: 18px;
  }
`;
const LogoDiv = styled.div`
  img {
    margin-top: 1vw;
    margin-left: 6vw;
    width: 12vw;
  }
  @media (max-width: 480px) {
    img {
      margin: 0;
      width: 70%;
    }
  }
`;
const LinkDiv = styled(Link)`
  text-decoration: none;
  color: ${(props) =>
    props.theme === "black" ? WHITE_COLOR : HEADER_TEXT_COLOR};
  &:hover {
    text-decoration: none;
    color: ${(props) =>
      props.theme === "black" ? WHITE_COLOR : HEADER_TEXT_COLOR};
  }
  @media (max-width: 480px) {
    color:${({theme})=>theme["header-text-color"]};
  }
`;

export { HeaderDiv, HeaderTextDiv, LogoDiv, LinkDiv };
