import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterDiv = styled.footer`
  background:${({ theme }) => theme["black-color"]};
  display: flex;
  height: 20vw;
  justify-content: space-between;
  padding: 4vw 5.4vw;
  @media (max-width: 480px) {
    height: 50vh;
    padding: 2rem 1rem 2rem 2rem;
    flex-direction: column;
    div {
      display: flex;
    }
  }
`;

const FooterTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 18vw;
  font-family: "Poppins";
  font-style: normal;
  text-align: start;
  color: ${({ theme }) => theme["text-color"]};
  h3 {
    font-weight: 600;
    font-size: 1.23vw;
    line-height: 1.84vw;
    margin-bottom: 0.2vw;
  }
  img {
    width: 50%;
  }
  p {
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.84vw;
    margin-bottom: 1vw;
  }
  h6 {
    font-family: "Rubik";
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1vw;
    line-height: 2.3vw;
    margin-top: 2vw;
    padding-left: 8vw;
  }
  @media (max-width: 480px) {
    width: 100%;
    h3 {
      font-size: 16px;
      line-height: 24px;
    }
    img {
      width: 80%;
      margin-bottom: 1rem;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
    h6 {
      font-size: 14px;
      line-height: 18px;
      margin-top: 2rem;
      padding-left: 0;
    }
  }
`;
const SocialIconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 12%;
  }
  @media (max-width: 480px) {
    width: 80%;
    flex-wrap: wrap;
    justify-content: start;
    gap: 8px;
    img {
      width: 25%;
    }
  }
`;

const LogoDiv = styled.div`
  img {
    margin-top: 0.5vw;
    margin-left: -1.2vw;
    width: 50%;
  }
  @media (max-width: 480px) {
    img {
      margin: 0;
      width: 70%;
    }
  }
`;
const LinkDiv = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme["text-color"]};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme["text-color"]};
  }
  @media (max-width: 480px) {
    color: ${({ theme }) => theme["text-color"]};
  }
`;

export { FooterDiv, FooterTextDiv, SocialIconsDiv, LogoDiv, LinkDiv };
